export const baseTheme = {
  colors: {
    background: "#3D3D3D",
    text: "#c8c8c9",
    subtext: "#868281",
    pink: "#eb4888",
    green: "#24d05a",
    blue: "#10a2f5",
    yellow: "#e9bc3f",
    orange: "#F8AA0D",
    cyan: "#33FAFF",
    purple: "#BC70F7",
    limegreen: "#DAF7A6",
    salmon: "#FF5733",
  },
};
