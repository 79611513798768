import React from "react";
import { withTheme } from "styled-components";
import copy from "copy-text-to-clipboard";
import Swal from "sweetalert2";
import { Box } from "../components/shared/Box";
import { Link } from "../components/shared/Link";
import { Typography } from "../components/shared/Typography";
import { config } from "../config";

interface LandingProps {
    theme: any;
  }
  
  // Function starts here. Landing2 is the function name

  const Landing2 = ({ theme }: LandingProps) => {
    const handleCopyDiscord = () => {
      if (copy(config.discord)) {
        Swal.fire({
          title: "Success!",
          text: "My discord tag has been copied to your clipboard!",
          icon: "success",
          confirmButtonText: "Ok",
        });
      } else {
        Swal.fire({
          title: "Failed!",
          text: "Could not copy my discord tag to your clipboard!",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    };
  
    return (
      //main <Box> tag for the whole page
      <Box display="flex" flexDirection="column" margin="30px auto" paddingX="30px" style={{ gap: "30px" }}>
        
        
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Box>
              <Typography fontSize="25px">Attitude is Everything</Typography>
            </Box>
            <Box>
              <Typography fontSize="20px" color={theme.colors.subtext}>
                Vicky Bagwalla
              </Typography>
            </Box>
          </Box>
        </Box>
                       
        <Box display="flex" style={{ gap: "30px", }}>        
        
        <Box>
          <Link color={theme.colors.cyan} target="_blank" href={config.links.twitter}>
            twitter
          </Link>
        </Box>

        <Box onClick={handleCopyDiscord} color={theme.colors.yellow} style={{ cursor: "pointer", textDecoration: "underline", }}>
          <Typography color={theme.colors.yellow}>discord</Typography>
        </Box>
        
        <Box>
          <Link color={theme.colors.pink} target="_blank" href={config.links.github}>
            github
          </Link>
        </Box>
        
        <Box>
          <Link color={theme.colors.blue} target="_blank" href={config.links.linkedin}>
            LinkedIn
          </Link>
        </Box>
        
        <Box>
          <Link color={theme.colors.green} target="_blank" href={config.links.wallet}>
            wallet
          </Link>
        </Box>
      
      </Box>
      
      <Box display="flex" style={{ gap: "30px", }}>
          <Box>
            <Typography fontSize="20px" color={theme.colors.purple}>
              Introduction
            </Typography>
          </Box>
        </Box>
        
      <Box maxWidth="700px" justifyContent="center" alignItems="center">
        <Typography style={{ fontStyle: "italic"}} fontSize="16px" color={theme.colors.limegreen}>
          How are you keeping yourself from achieving your goals?
        </Typography>
      </Box>  

      <Box maxWidth="700px" justifyContent="space-between" alignItems="center">
        <Typography>
          "Attitude is everything" was written to show you how you how an attitude developed 
          by you will follow you in everything you do. How you talk, interact with people, 
          conduct a personal interaction with a friend, conduct a business meeting. Everything. 
        </Typography>
      </Box>

      <Box maxWidth="700px" justifyContent="space-between" alignItems="center">
        <Typography>
          It is the fundamental pillar of who you are, your outlook on life and how you conduct 
          yourself in your day-to-day life. 
        </Typography>
      </Box>

      <Box maxWidth="700px" justifyContent="space-between" alignItems="center">
        <Typography>
          Generations continue to change in their core beliefs of the "truth". They are raised 
          in a society that dictates what is right and what is wrong. Society has an ever changing 
          and ever evolving definition of the same. Let us examine that for a minute. 99% of the population 
          lives a life that is not entirely theirs. They navigate life through the beliefs of society. 
          Don't get me wrong, I am not suggesting that one should be a social exile.
        </Typography>
      </Box>

      <Box maxWidth="700px" justifyContent="space-between" alignItems="center">
        <Typography style={{ fontStyle: "bold"}} fontSize="16px" color={theme.colors.orange}>
          [*] Change Log: Last Updated: April-05-2022
        </Typography>
      </Box>

         
      </Box>
      //main <Box> tag for the whole page ends here

    );
    //return closed in the line above

  };
  //Landing2 function closed in the line above
  
  export default withTheme(Landing2);
