interface Config {
  [key: string]: any;
}

export const config: Config = {
  links: {
    twitter: "https://twitter.com/vickyb",
    linkedin: "https://www.linkedin.com/in/vicky-bagwalla/",
    github: "https://github.com/nicholhai",
    wallet: "https://unstoppabledomains.com/d/vickybagwalla.wallet",
    opensea: "https://opensea.io/vickyb",
    ipfs: "https://ipfs.io/",
    instagram: "https://instagram.com/vbagwalla",
    julian: "https://julian-o.com",
  },

  discord: "Nicholai#2103",

  version: process.env.REACT_APP_VERSION,

  env: process.env.REACT_APP_ENV,
};
